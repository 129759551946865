<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>{{this.singular}}: {{this.data.name}}</h1>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="isAllowed('productCategory','u')" @click="isEditable = !isEditable">
            <span v-if="this.isEditable">Cancel</span>
            <span v-else>Edit</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="isEditable">
          <v-btn @click="update">Save</v-btn>
          <v-btn v-if="isAllowed('productCategory','d')" style="margin-left: 10px;" class="error" @click="deleteData">Delete</v-btn>
        </v-col>
      </v-row>
      <v-form :disabled="!isEditable">
        <v-row>
          <v-col>
            <v-text-field
                label="Name"
                outlined
                v-model="data.name"
            />
            <v-text-field
                label="Slug"
                outlined
                v-model="data.slug"
                persistent-hint
                hint="Spaces within the Slug will be replaced with '-' automatically."
            />
            <v-text-field
                label="Description"
                outlined
                v-model="data.description"
            />
            <v-autocomplete
                item-text="name"
                item-value="id"
                :items="categories"
                clearable
                label="Parent Category"
                outlined
                v-model="data.parent"
            />
            <span>
              <h2>Image</h2>
              <div v-if="data.image" class="d-flex flex-row flex-wrap">
                <span class="mr-1" v-for="(img, i) in data.image.media" :key="i">
<!--                  <span v-if="isEditable" style="position: absolute; background: #2196f3;" class="" @click="openImageSettings(img, data)"><v-icon color="white">mdi-cog</v-icon></span>-->
                  <span v-if="isEditable" style="position: absolute; margin-left: 176px; background: #ff5252;" class="" @click="deleteImage()"><v-icon color="white">mdi-close</v-icon></span>
                  <span><img style="cursor: pointer; border: 2px solid #777777; width: 200px;" @click="openImage(img.url)" v-if="img.type==='image'" :src="img.url" alt=""></span>
                </span>
                <div  class="mx-1">
                </div>
              </div>
              <div v-else>
                <span>No images.</span>
              </div>
              <v-file-input
                  label="Upload Image Here"
                  v-if="isEditable && (!data.image || (data.image.media && data.image.media.length===0))"
                  show-size
                  v-model="imageUploader"
                  @change="uploadImage($event)"
              ></v-file-input>
            </span>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col>
          <hr>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <h1>Products</h1>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
              :headers="secondaryHeaders"
              :items="data.Products"
              :items-per-page="-1"
              class="elevation-1"
              @click:row="secondaryRowClick"
          >
            <template v-slot:item.regularPrice="{ item }">
              <span>{{formatPrice(item.regularPrice) }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,
        data: {},
        categories: [],

        singular: "Product Category",
        singularLower: "productcategory",
        plural: "Product Categories",
        pluralLower: "productcategories",
        isEditable: false,
        secondaryHeaders: [
          {
            text: 'Product',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'SKU', value: "sku"},
          { text: 'Price', value: "regularPrice"},
          { text: 'Description', value: "description"}
        ],

        imageUploader: null,
        imageData: {
          isOpen: false,
          data: {tags: ["thumbnail"], type: 'image', name: ''},
          product: null,
          loading: false,
          img: null
        },
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed"])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async update(){
        try {
          this.loader = true;
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`, this.data)
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} Updated 🎉`, "success");
          this.data={};
          await this.getAllData();
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async deleteData(){
        try {
          this.loader = true
          let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} Deleted 🎉`, "success");
          await this.$router.go(-1);
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      secondaryRowClick(row){
        this.$router.push({ path: `/products/view/${row.id}`})
      },
      async getAllData(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error
          this.data = res.data.data

          let uts = await axios.get(`${this.getEndpoint}/api/productcategories`);
          if(uts.data.error) throw uts.data.error

          this.categories = uts.data.data.filter(category => {
            return category.id!==this.data.id;
          });

        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async uploadImage(file){
        try {
          if(!this.data.id) throw "Category ID is missing."
          this.loader = true;
          this.imgToUpload = file
          let theFile = new FormData()
          theFile.append('image', this.imgToUpload, "image")
          let res = await axios.post(`${this.getEndpoint}/api/productcategories/${this.data.id}/uploadImage`, theFile)
          if(res.data.success){
            this.data.image = res.data.data;
            file = null;
            this.imageUploader = null;
          }else throw res.data.error
        } catch (error) {
          console.log(error)
        } finally {
          this.loader = false
          this.imgToUpload = null
        }
      },
      async deleteImage(){
        try {
          if(!this.data.id) throw "Category ID is missing."
          this.loader = true;

          let verify = confirm("Are you sure you want to delete this image? This action cannot be reversed.");
          if(!verify) return;

          let res = await axios.delete(`${this.getEndpoint}/api/productcategories/${this.data.id}/deleteImage`)
          if(res.data.success){
            this.data.image = res.data.data;
          }
          else
            throw "Error: Image was not deleted."
        } catch (error) {
          console.error(error);
        } finally {
          this.loader = false
        }
      },
      openImage(url){
        window.open(url, '_blank').focus();
      },
      formatPrice(value){
        if(value===null) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
      },
    }
  }
</script>
